import styled, { css } from 'styled-components';

export const DIMEN_HEADER_HEIGHT = '5.875rem';
export const DIMEN_HEADER_HEIGHT_MOBILE = '4rem';

export const DIMEN_BOXED_CONTENT_WIDTH = 1280;

export const DIMEN_BREAKPOINT_XS = 375;
export const DIMEN_BREAKPOINT_SM = 576;
export const DIMEN_BREAKPOINT_MD = 768;
export const DIMEN_BREAKPOINT_LG = 992;

export const BREAKPOINT = (breakpoint: number) => `@media (min-width: ${breakpoint}px)`;
export const BREAKPOINTMAX = (breakpoint: number) => `@media (max-width: ${breakpoint - 1}px)`;

export const BREAKPOINT_XS = BREAKPOINT(DIMEN_BREAKPOINT_XS);
export const BREAKPOINT_SM = BREAKPOINT(DIMEN_BREAKPOINT_SM);
export const BREAKPOINT_MD = BREAKPOINT(DIMEN_BREAKPOINT_MD);
export const BREAKPOINT_LG = BREAKPOINT(DIMEN_BREAKPOINT_LG);
export const BREAKPOINT_XL = BREAKPOINT(DIMEN_BOXED_CONTENT_WIDTH);
export const MAX_BREAKPOINT_XL = BREAKPOINTMAX(DIMEN_BOXED_CONTENT_WIDTH);

export const FONT_STANDARD = '"Inter", sans-serif';
export const FONT_HEADLINES = '"PPMonumentExtended", sans-serif';

export const CoverImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  pointer-events: none;
  user-select: none;
`;

export const BoxedContent = css`
  margin: 0 auto;
  width: 100%;
  max-width: ${DIMEN_BOXED_CONTENT_WIDTH}px;
`;
