import { FONT_STANDARD, FONT_HEADLINES } from './General';
import { css } from 'styled-components';

export const ColorTheme = css`
  :root {
    // base colors
    --color-black-900: #0F0F0F;
    --color-grey-900: #1A1A1A;
    --color-grey-800: #373737;
    --color-grey-700: #666666;
    --color-grey-600: #ADADAD;
    --color-grey-500: #D9D9D9;
    --color-grey-400: #F0F0F0;
    --color-grey-300: #FAFAFA;
    --color-white: #FFFFFF;
    --color-black: #000000;

    // signal colors
    --color-signal-red: #FF0000;
    --color-signal-yellow: #F1CB6A;
    --color-signal-orange: #FFA800;
    --color-signal-green: #37B960;

    // theme colors
    --color-primary: #08E8DE;
    --color-primary-dark: #185F5B;
    --color-primary-rgb: 8, 232, 222;

    --color-theme-purple: #5800FF;
    --color-theme-lightPurple: #F4F1FB;
    --color-theme-green: #85FF56;
    --color-theme-lightGreen: #E9FFE0;
    --color-theme-pink: #FF9ECF;
    --color-theme-lightPink: #FFF3F9;
    
    // element colors
    --color-element-boarder-primary: var(--color-theme-purple);
    --color-element-boarder-secondary: var(--color-theme-green);
    
    --color-element-boarder-card: var(--color-element-boarder-primary);
    --color-element-boarder-input: var(--color-element-boarder-secondary);
  }
`;


export interface ThemeProps {
  colors: {
    purple: string;
    brightPurple: string;
    lila: string;
    lightPurple: string;
    pink: string;
    brightPink: string;
    lightPink: string;
    green: string;
    lightGreen: string;
    gray: string;
    darkGray: string;
    white: string;
    black: string;
    red: string;
  };
  fonts: {
    default: string;
    defaultBold: string;
    defaultHeadline: string;
  };
}

export const Theme: ThemeProps = {
  colors: {
    purple: '#5800FF',
    brightPurple: '#D4BEFF',
    lila: '#BC99FF',
    lightPurple: '#F4F1FB',
    green: '#85FF56',
    lightGreen: '#E9FFE0',
    pink: '#FF9ECF',
    brightPink: '#FFD3E9',
    lightPink: '#FFF3F9',
    gray: '#F2F2F2',
    darkGray: '#707070',
    white: '#ffffff',
    black: '#000000',
    red: '#FF0000',
  },
  fonts: {
    default: FONT_STANDARD,
    defaultBold: FONT_STANDARD,
    defaultHeadline: FONT_HEADLINES,
  },
};

export type ThemeColorKey = keyof typeof Theme.colors;
export type ThemeFontKey = keyof typeof Theme.fonts;

export const getTheme = (callback: (theme: ThemeProps, props: any) => any) => (
  ({ theme, ...props }: any) => callback(theme, props)
);
export const getThemeColor = (color: ThemeColorKey) => getTheme(({ colors }) => colors[color]);
export const getThemeFont = (font: ThemeFontKey) => getTheme(({ fonts }) => fonts[font]);